import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { StaticImage } from "gatsby-plugin-image";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import ImagesTop from "./ImagesTop";
export const _frontmatter = {
  "title": "Huey Mobile"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout className="page huey-mobile-page" mdxType="Layout">
      <Seo title={props.pageContext.frontmatter.title} mdxType="Seo" />
      <div className="col-10 offset-1">
        <h1>{`Huey Mobile`}</h1>
        <p>{`Huey Mobile is an app that enhances water and energy efficiency through real time monitoring and intuitive
data presentation for the entire household. Huey Mobile is the main user interface for the Huey Cloud platform.`}</p>
        <br />
        <div className="text-center">
          <a href="https://apps.apple.com/au/app/huey-mobile/id1600003033"><img src={'/static/images/apple-app-store-badge.svg'} alt={'Apple App Store Badge'} /></a>
        </div>
        <br />
        <h2>{`Features`}</h2>
        <ul>
          <li parentName="ul">{`Alerts are configurable to prevent and mitigate accidents and emergencies such as devices left on, water leaks and pipe bursts.`}</li>
          <li parentName="ul">{`Historical data is viewable by day and week.`}</li>
          <li parentName="ul">{`Data channels may be shared to other household members who install Huey Mobile on their phones`}</li>
        </ul>
        <p>{`This app works with any data channels (sensors) you have in your Huey account. Channels are added by either purchasing
sensors (eg. `}<a parentName="p" {...{
            "href": "/products/huey-h2o"
          }}>{`Huey H₂O`}</a>{`) or by a sensor owner sharing a channel with you.`}</p>
        <h2>{`Privacy`}</h2>
        <p>{`Privacy is a top priority. We don't ask for your actual name or physical address. Your specific location is never
requested or captured. We only ask for the approximate area of your sensor via neighbourhood-level geolocation.
`}<a parentName="p" {...{
            "href": "/platform/huey-mobile/privacy"
          }}>{`Privacy Policy`}</a></p>
        <h2>{`Support`}</h2>
        <p>{`See `}<a parentName="p" {...{
            "href": "/platform/huey-mobile/support"
          }}>{`Huey Mobile Support Page`}</a></p>
        <br />
        <div className="w-50 mx-auto">
          <ImagesTop mdxType="ImagesTop" />
        </div>
        <br />
        <br />
      </div>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      